<template>
<div>
 <v-scale-screen :fullScreen="false" width="1920" height="1080">
  <!-- 预览15个摄像头 -->
  <div class="show_box" v-if="show">
    <div class="sp_body">
      <!-- <div class="close" @click="close">×</div>
      <div class="groupTitle">{{ groupTitle }}</div> -->
      <!-- <div class="sp_item" v-for="(item,index) in urls" :key="index">
        <div :id="'video'+index"></div>
      </div> -->

      <div class="title">
        <div class="title_title">{{ groupTitle }}</div>
        <div class="title_X" @click="allClose">×</div>
      </div>
      <AllVideoMode v-if="allVideoShow" ref="allVideoMode" @closeAllVideo="closeAllVideo"></AllVideoMode>

      <div class="btn">
        <el-button @click="previous" :disabled="previousFlg">上一页</el-button>
        <span style="margin-left: 50px;color: white;">{{i}}/{{totalPage}}</span>
        <el-button style="margin-left: 50px;" @click="next" :disabled="nextFlg">下一页</el-button>
      </div>
    </div>
  </div>

  <!-- 预览1个摄像头 -->
  <!-- <div style="position: absolute;top:250px;left:500px;z-index: 999;height: 600px;width: 50%;margin: 0 auto;" v-if="showOne" >
    <div style="height: 42px;background: linear-gradient(90deg, #0099FF 0%, #0D1836 100%);">
      <img style="height: 42px;brightness(200%);float: left;" :src="require('@/assets/img/' + 'Vector.svg')"/>
      <span style="line-height: 42px;color: white;brightness(200%);font-weight: 800;font-size: 25px;font-family:">{{ oneName }}</span>
      <span style="line-height: 40px;float: right;color: white;margin-right: 10px;font-size: 25px;" @click="close('item')">×</span>
    </div>
    <div style="height: 5px;width: 100%;"></div>
    <div style="height: 500px;width: 100%;padding-top: 25px;background: linear-gradient(90deg, #0D1836 55%, #0D1836 100%);">
      <div id="oneVideo" style="width: 95%;height: 480px;margin: 0 auto;border-radius: 2%;"></div>
    </div>
  </div> -->

  <div class="newScreen">
    <div class="videobg">
      <div class="overlay"></div>
            <video autoplay muted loop objectFit="cover" style="width:100%;height:100%;object-fit: cover">
                <source src="@/assets/img/bg.mp4" type="video/mp4">
            </video>
          </div>

      <!-- 顶部 -->
        <div class="top">
          <div class="top_title">监控中心</div>
          <div class="top_title_people">
            <div class="top">
              <img class="left" src="@/assets/img/tel_phone.png" alt="">
              <div class="right">
                服务终端在线人数
              </div>
            </div>
            <div class="bottom">{{$store.state.onlinePoeple}}</div>
          </div>
        </div>
        
        <div class="cont">
            <!-- 左中 -->
        <div class="contLeft">
          <div class="nav_left">
            <div class="flex_space flex_align" style="margin-left: 46px;width: 503px;">
              <div class="nav_left_item" v-for="(item,index) in leftList" :key="index" @click="showMode(item,index)">
                <div class="nav_left_img"><img  :src="item.icon" style="width:100%;height:100%"/></div>
                <div class="nav_left_name">{{item.name}}</div>
                <div class="nav_left_num">{{ item.num || 0 }}</div>
              </div>
              <img src='@/assets/img/return.png'  style="height:70px;width:70px;margin-left:8px;z-index:100;display:block" @click="goHome()"/>
            </div>
          </div>
          <div class="nsLeft">
            <div class="left-center">
                <div class="flex_space flex_center_align" style="margin-left:24px">
                  <div class="lc_title">监控总览</div>
                </div>
                <div class="line"></div>
                <div class="panel" style="height: 811px;margin: 24px;overflow:auto;">
                  <div class="p_line_left">
                    <el-tree :data="treeData" icon-class=" ">
                      <template slot-scope="{ node, data }">
                          <div style="display: flex;align-items: center;justify-content: space-between;width: 100%;" @click="handleNodeClick(data)">
                          <div style="display: flex;align-items: center;">
                            <div>
                              <div v-if="data.type === 'parent'" style="height: 10px;width: 10px;background-color: white;brightness(200%);"></div>
                              <img v-else-if="data.type === 'child'"  style="brightness(200%);height: 40px;" :src="require('@/assets/img/' + 'Vector.svg')"/>
                            </div>
                            <div style="height: 40px;line-height: 40px;margin-left: 10px;font-family:'Source Han Sans CN'" >{{data.label}}</div>
                          </div>
                            <div>
                              <img v-if="data.type === 'parent' && !node.expanded" style="brightness(200%);height: 40px;float: right;" :src="require('@/assets/img/' + 'Group-900.png')"/>
                              <img v-else-if="data.type === 'parent' && node.expanded" style="brightness(200%);height: 40px;float: right;" :src="require('@/assets/img/' + 'Group-817.png')"/>
                              <img v-else-if="data.type === 'child'" style="brightness(200%);height: 40px;float: right;" :src="require('@/assets/img/' + 'Group-900.png')"/>
                            </div>
                          </div>
                      </template>
                    </el-tree>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="contCenter">
          <div class="nscenter">
            <!-- 地图 -->
            <div class="mapCss mapCity">
              <div ref="mapBox" style="width:100%;height:100%"> </div>
            </div>
            <div class="center_bottom">
              <div class="left-center center_bleft">
                  <div style="width: 900px;height: 300px;overflow: auto;margin: 0 auto;margin-top: 80px;">
                    <div v-for="(item,index) in groupList" :key="index" @click="queryVideo(item)" style="background: linear-gradient(90deg, #0d1836 0%, rgba(0, 191, 255, 0) 100%);width: 191px;height: 39px;margin: 10px;float: left;">
                      <img style="height: 39px;brightness(200%);float: left;" :src="require('@/assets/img/' + 'Vector.svg')"/>
                      <span style="line-height: 39px;color: white;brightness(200%);font-weight: 700;font-family:'JDLangZhengTi'">{{item.name }}</span>
                      <img style="height: 39px;float: right;brightness(200%)" :src="require('@/assets/img/' + 'Group-900.png')"/>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

            <!-- 右 -->
        <div class="contRight">
          <div class="nsright">
            <div class="left-center">
                <div class="flex_space flex_center_align">
                  <div class="lc_title">重点区域</div>
                </div>
                <div class="line"></div>
                  <div class="panel">
                    <div style="width: 320px;height: 185px;margin: 20px auto;" v-for="(item,index) in urlsMajor" :key="index">
                        <img style="height: 39px;brightness(200%);float: left;" :src="require('@/assets/img/' + 'Vector.svg')"/>
                        <span style="line-height: 39px;color: white;brightness(200%);font-weight: 800;font-family:'Source Han Sans CN'" @click="videoDialog(item,'right')">{{item.cameraName }}</span>
                        <div style="float: right;height: 150px;width: 300px;" :id="'major'+index"></div>
                    </div>
                </div>
            </div>
          </div>
        </div>  
    </div>
  </div>
  <newMode ref="newMode"></newMode>
  <OneVideoMode v-if="oneVideoShow" ref="oneVideoMode" @closeOneVideo="closeOneVideo"></OneVideoMode>
 </v-scale-screen>
</div>
</template>
<script>
import newMode from '@/components/newMode.vue'
import OneVideoMode from '@/components/oneVideoMode.vue'
import AllVideoMode from '@/components/allVideoMode.vue'
import { mapdata, geoCoordMap, coordinates } from './utils/mapCenterPoint'
import { mapOption } from './utils/options.js'
import { vipCarDateList, vipCarList, postLogin, getDangIndex, selectTree, getTokenVideo, selectCameraByRegion, selectGroupCamera, selectMajorCamera, getLineClass, articleInfoList, getBankList, searchReadNums } from '@/api/api.js'
import EZUIKit from 'ezuikit-js'
const mapData = require('./utils/newcun.json')
import * as echarts from 'echarts'
// var player = null
// var start = 0
// var page = 15
export default {
  components: {
    newMode,
    OneVideoMode,
    AllVideoMode
  },
  data() {
    return {
      leftList: [
        {
          name: '民众心声',
          icon: require('./assets/img/imz.png'),
          type: '3',
          ptype: 'voxPop',
          num: 0
        },
        {
          name: '全民广场',
          icon: require('./assets/img/iqm.png'),
          type: '2',
          ptype: 'nationalSquare',
          num: 0
        },
        {
          name: '在线课堂',
          icon: require('./assets/img/isk.png'),
          type: '1',
          ptype: 'readBook',
          num: 0
        },
        {
          name: '道德银行',
          icon: require('./assets/img/ibank.png'),
          type: '4',
          ptype: 'releaseReq',
          num: 0
        },
        {
          name: '贵客到访',
          icon: require('./assets/img/car.png'),
          type: '5',
          ptype: 'monitor',
          num: 0
        }
      ],
      zpajList: [{ fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }],
      total: { familyNum: 0, residentNum: 0, adminUserNum: 0, oftenNum: 0, foreignNum: 0, flowNum: 0, rangerNum: 0, impatientNum: 0 },
      partyNums: {},
      memberCount: {},
      treeData: [],
      groupList: [],
      baseform: {},
      show: false,
      showOne: false,
      urls: [],
      urlsMajor: [],
      pingfangziaccessToke: '',
      nextFlg: false,
      previousFlg: true,
      i: 1,
      totalPage: 1,
      managementId: '',
      oneName: '',
      groupTitle: '',
      nerwstimer: '',

      player: null,
      start: 0,
      page: 1,
      rows: 15,
      oneVideoShow: false,
      allVideoShow: false
    }
  },
  watch: {
    i: {
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal);
        this.nextFlg = true
        this.previousFlg = true

        if (newVal == this.totalPage) {
          setTimeout(() => {
            this.nextFlg = true
            this.previousFlg = false
          }, 2500)
        } else if (newVal == 1) {
          setTimeout(() => {
            this.previousFlg = true
            this.nextFlg = false
          }, 2500)
        } else {
          setTimeout(() => {
            this.nextFlg = false
            this.previousFlg = false
          }, 2500)
        }
      }
      // deep:true
    }
  },
  methods: {
    closeAllVideo() {
      this.allVideoShow = false
    },
    closeOneVideo() {
      this.oneVideoShow = false
    },
    goHome() {
      this.$router.replace('/integrated')
    },
    initMap() {
      var convertData = function(data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name]
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value * 2)
            })
          }
        }
        return res
      }

      echarts.dispose(this.$refs.mapBox)
      var myChart = echarts.init(this.$refs.mapBox)

      var option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0, 153, 255, 0.82)',
          borderColor: '#FFFFCC',
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0,
          extraCssText: 'z-index:100',
          formatter: function(params, ticket, callback) {
            //根据业务自己拓展要显示的内容
            var res = ''
            var name = params.name
            // console.log(name);
            res = "<span style='color:#fff;'>" + name + '</span>'
            return res
          }
        },
        geo: {
          map: 'xiaopingfang',
          aspectScale: 1, //长宽比
          zoom: 1.15,
          roam: false,
          itemStyle: {
            normal: {
              // areaColor: '#00FFFF', // 地图颜色
              // borderWidth: 8, // 边框的宽度
              shadowColor: '#0099ff', //  阴影颜色
              borderColor: '#00FFFF', // 边框颜色
              shadowBlur: 12, // 阴影的模糊大小
              shadowOffsetX: 0, //阴影水平方向上的偏移距离
              shadowOffsetY: 15, // 阴影垂直方向上的偏移距离
              areaColor: {
                x: 1000,
                y: 1000,
                x2: 1000,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#69c5d8' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#126caf' // 50% 处的颜色
                  }
                ],
                global: true // 缺省为 false
              },
              opacity: 1
            },
            emphasis: {
              areaColor: '#2AB8FF',
              borderWidth: 0,
              color: 'green',
              label: {
                show: false
              }
            }
          },
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          scaleLimit: {
            //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 0.8, //最小的缩放值
            max: 3 //最大的缩放值
          }
        },
        series: [
          {
            type: 'map',
            map: 'xiaopingfang', //使用
            roam: false,
            aspectScale: 1,
            // geoIndex: 0,
            zoom: 1.15,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false
              }
            },

            itemStyle: {
              normal: {
                areaColor: '#334fa3', //正常背景颜色
                // borderColor: '#4F8DBB',
                borderColor: '#8cD2',
                borderWidth: 1.5
              },
              emphasis: {
                areaColor: '#2AB8FF', //选中颜色
                borderWidth: 0,
                color: 'green',
                show: false
              }
            },
            scaleLimit: {
              //所属组件的z分层，z值小的图形会被z值大的图形覆盖
              min: 0.8, //最小的缩放值
              max: 3 //最大的缩放值
            }
          },
          {
            name: 'city',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            geoIndex: 0,
            data: convertData(mapdata),
            symbolSize: 5,
            showEffectOn: 'render',
            //涟漪特效相关配置
            rippleEffect: {
              brushType: 'stroke',
              period: 1,
              scale: 2 //动画中波纹的最大缩放比例。
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true,
                color: '#F5F5F5',
                fontWeight: 'bold',
                fontSize: '12'
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                shadowColor: '#0098d9', //  阴影颜色
                shadowBlur: 30,
                shadowOffsetX: 0, //阴影水平方向上的偏移距离
                shadowOffsetY: 20, // 阴影垂直方向上的偏移距离
                emphasis: {
                  areaColor: '#2B91B7'
                }
              }
            },
            zlevel: 1
          },
          {
            type: 'lines',
            polyline: true,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.3,
              symbolSize: 6
            },
            lineStyle: {
              width: 1.5,
              color: '#7ee0ff',
              opacity: 0.5
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: coordinates,
            zlevel: 10
          }
        ]
      }

      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, {
      //   renderer: 'svg',
      // })
      // map.clear()
      myChart.setOption(option)
    },
    initChart() {
      this.initMap()
      // 地图绘制
      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, { renderer: 'svg' })
      // map.clear()
      // map.setOption(mapOption)
    },
    showMode(item, index) {
      console.log(item)
      if (['3', '2'].includes(item.type)) {
        this.searchArticles(item.type, item)
      } else if (item.type == '4') {
        // 道德银行
        this.searchBnakList(item)
      } else if (item.type == '1') {
        getLineClass().then(res => {
          if (res.code == '0') {
            if (!res.model.length) {
              this.$message.error('暂无数据')
              return
            }
            let newData = {
              form: item,
              list: res.model
            }
            this.$refs.newMode.show = true
            this.$refs.newMode.initData(newData)
          }
        })
      } else if (item.type == '5') {
        this.vipCarList(item, index)
      }
    },
    vipCarList(item, index) {
      vipCarDateList().then(res => {
        if (res.code == '0') {
          console.log('res', res)
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchArticles(type, data) {
      let params = {
        townId: 6281,
        page: 1,
        rows: 100000,
        articleType: 1,
        articleSubtype: type
      }
      articleInfoList(params).then(res => {
        if (res.code == '0') {
          this.articleList = res.items
          if (!res.items.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: data,
            articleList: res.items
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchBnakList(item) {
      getBankList().then(res => {
        if (res.code == '0') {
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    // 登录
    submitForm(formName) {
      let form = {
        username: 'xiaopingfang',
        password: '123456'
      }
      postLogin(form)
        .then(res => {
          if (res.code == '0') {
            // sessionStorage.clear()
            this.resetSetItem('userInfo', JSON.stringify(res.model))
            sessionStorage.setItem('token', res.model.tokenValue)
            this.searchDetails(res.model.regionId)
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    searchDetails(regionId) {
      getDangIndex({ villageId: regionId }).then(res => {
        if (res.code == 0) {
          // this.totalNums = res.model.memberCount
          // this.$nextTick(() => {
          //   this.drowFunction(res.model)
          // })
        }
      })
    },
    // 获取树形图
    getTree() {
      // this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
      selectTree().then(res => {
        this.treeData = res.model
      })
    },
    // 点击节点
    handleNodeClick(data) {
      if (data.type == 'child') {
        // this.oneName = data.label
        // console.log(data.url)
        // this.showOne = true
        // this.$nextTick(() => {
        //   this.initEzuikOne(data.url, 'oneVideo')
        // })
        this.oneVideoShow = true
        this.$nextTick(() => {
          this.$refs.oneVideoMode.initData(data, this.pingfangziaccessToke)
        })
      }
    },
    // 获取片区
    getDetail() {
      // this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
      selectGroupCamera().then(res => {
        this.groupList = res.model
      })
    },
    // 获取重点区域
    getMajor() {
      // this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
      selectMajorCamera().then(res => {
        this.urlsMajor = res.model
        this.$nextTick(() => {
          res.model.forEach((item, index) => {
            setTimeout(() => {
              let id = 'major' + index
              this.initEzuikMajor(item.cameraUrl, id, index + 1)
            }, 0)
          })
        })
      })
    },
    allClose() {
      this.$refs.allVideoMode.close()
      this.$nextTick(() => {
        this.show = false
        this.page = 1
        this.i = 1
        // this.totalPage = 1
        this.previousFlg = true
        this.nextFlg = false
      })
    },
    // 关闭预览
    // close() {
    //   if (this.player) {
    //     this.player.stop()
    //     this.player = null
    //   }
    //   this.show = false
    //   // this.showOne = false
    // },
    // 上一页
    previous() {
      this.$refs.allVideoMode.close()
      this.i -= 1
      this.page -= 1
      this.nextInitData()
    },
    // 下一页
    next() {
      // if (this.page == this.totalPage) {
      //   this.nextFlg = true
      // } else {
      //   this.nextFlg = false
      //   this.previousFlg = false
      // }
      this.$refs.allVideoMode.close()
      this.i += 1
      this.page += 1
      this.nextInitData()
    },
    // 下一页预览
    nextInitData() {
      let params = {
        managementId: this.managementId,
        pingfangziaccessToke: this.pingfangziaccessToke,
        page: this.page,
        rows: this.rows
      }
      selectCameraByRegion(params).then(res => {
        if (res.code == '0') {
          this.show = true
          this.allVideoShow = true
          this.$nextTick(() => {
            // res.model.forEach((item, index) => {
            //   if (start <= index && index < page) {
            //     setTimeout(() => {
            //       let id = 'video' + index
            //       this.initEzuik(item.cameraUrl, id, index + 1)
            //     }, 0)
            //   }
            // })
            let data = res.items
            this.$refs.allVideoMode.initData(data, this.pingfangziaccessToke)
          })
        } else {
          this.$message.error(res.error)
        }
      })
    },
    // 获取Token
    getTokenpingfangzi() {
      let datapfz = {
        appKey: 'a145fefba06a4bfb8ac283db040085a1',
        appSecret: '05e998d7e5044c346bedbb501c8747a7'
      }
      getTokenVideo(datapfz).then(res => {
        if (res.code == 200) {
          this.pingfangziaccessToke = res.data.accessToken
          this.getMajor()
        }
      })
    },
    // 获取15个摄像头地址
    queryVideo(e) {
      const loading = this.$loading({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        loading.close()
        // this.show = true
        console.log(e)
        this.managementId = e.id
        this.groupTitle = e.name
        let params = {
          managementId: e.id,
          pingfangziaccessToke: this.pingfangziaccessToke,
          page: this.page,
          rows: this.rows
        }
        selectCameraByRegion(params).then(res => {
          if (res.code == 0) {
            if (res.items.length == 0) {
              this.$message.error('暂无数据')
              return
            }
            // this.urls = res.model
            this.totalPage = res.totalPage
            this.show = true
            this.allVideoShow = true
            // this.totalPage = Math.ceil(res.model.length / 15)
            this.$nextTick(() => {
              // res.model.forEach((item, index) => {
              //   if (index < page) {
              //     setTimeout(() => {
              //       let id = 'video' + index
              //       this.initEzuik(item.cameraUrl, id, index + 1)
              //     }, 0)
              //   }
              // })
              // let data = res.model.filter((item, index) => {
              //   if(index < page)  return item
              // })
              let data = res.items
              // console.log('====>',data);
              this.$refs.allVideoMode.initData(data, this.pingfangziaccessToke)
            })
          } else {
            this.$message.error(res.message)
          }
        })
      }, 0)
    },
    // 预览
    // initEzuik(url, id, index) {
    //   console.log('url', url)
    //   player = new EZUIKit.EZUIKitPlayer({
    //     autoplay: true,
    //     id: id,
    //     accessToken: this.pingfangziaccessToke,
    //     url: url,
    //     template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
    //     audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
    //     width: '100%',
    //     height: '200px'
    //   })
    // },
    // 重点区域预览
    initEzuikMajor(url, id, index) {
      console.log('url', url)
      this.player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: id,
        accessToken: this.pingfangziaccessToke,
        url: url,
        template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
        width: '100%',
        height: '150px'
      })
    },
    // 单个节点预览
    // initEzuikOne(url, id) {
    //   console.log('url', url)
    //   player = new EZUIKit.EZUIKitPlayer({
    //     autoplay: true,
    //     id: id,
    //     accessToken: this.pingfangziaccessToke,
    //     url: url,
    //     template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
    //     audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
    //     width: '100%',
    //     height: '470px'
    //   })
    // },
    videoDialog(data, val) {
      this.oneName = data.cameraName
      console.log(data.cameraUrl)
      // this.showOne = true
      // this.$nextTick(() => {
      //   this.initEzuikOne(data.cameraUrl, 'oneVideo')
      // })
      this.oneVideoShow = true
      this.$nextTick(() => {
        this.$refs.oneVideoMode.initData(data, val, this.pingfangziaccessToke)
      })
    },
    searchNews() {
      this.nerwstimer = setInterval(() => {
        this.getReadnums()
      }, 10000) // 滚动速度
    },
    showMessage(content) {
      // this.content = content
      // this.show = true
      if (content) {
        let item = this.leftList.filter(item => {
          return item.type == content.type
        })[0]
        let index = this.leftList.findIndex(item => item.type == content.type)

        setTimeout(() => {
          item.num = item.num + 1
          this.$set(this.leftList, index, item)
          // this.content = {}
          // this.show = false
        }, 10000) // 3秒后隐藏消息
      }
    },
    getReadnums() {
      searchReadNums().then(res => {
        if (res.code == '0') {
          this.leftList.map(i => {
            if (i.ptype == 'voxPop') i.num = res.model.voxPopNum
            if (i.ptype == 'nationalSquare') i.num = res.model.nationalSquareNum
            if (i.ptype == 'readBook') i.num = res.model.onlineReadNum
            if (i.ptype == 'releaseReq') i.num = res.model.releaseReqNum
            // 贵客到访
            if (i.ptype == 'monitor') i.num = res.model.monitorNum
          })
          //终端在线人数
          this.$store.state.onlinePoeple = res.model.onlineNums
        }
      })
    }
  },
  mounted() {
    this.submitForm()
    this.getTokenpingfangzi()
    this.$nextTick(() => {
      this.getTree()
      this.getDetail()
      this.initChart()
    })

    //消息推送
    this.searchNews()
    this.getReadnums()
  },
  destroyed() {
    if (this.nerwstimer) window.clearInterval(this.nerwstimer)
  }
}
</script>
<style lang="scss">
@import '@/common/css/inter';
.el-tree {
  font-size: larger;
  color: #ffffff; // 白色字体
  background: rgba(0, 0, 0, 0); // 整体背景透明

  .el-checkbox__inner {
    background: rgba(0, 0, 0, 0); // checkbox 背景透明
  }

  .el-tree-node__content:hover {
    background: rgba(104, 147, 215, 0.4); // hover 节点背景色，否则是白色
  }

  .el-tree-node:focus > .el-tree-node__content {
    background-color: rgba(0, 0, 0, 0); // focus 节点背景透明，否则是白色
  }
}
</style>
<style lang="scss" scoped>
.p_line_left {
  width: 319px;
}
// .el-tree {
//   font-size: larger;
//   color: #ffffff; // 白色字体
//   background: rgba(0, 0, 0, 0); // 整体背景透明

//   .el-checkbox__inner {
//     background: rgba(0, 0, 0, 0); // checkbox 背景透明
//   }

//   .el-tree-node__content:hover {
//     background: rgba(104, 147, 215, 0.4); // hover 节点背景色，否则是白色
//   }

//   .el-tree-node:focus > .el-tree-node__content {
//     background-color: rgba(0, 0, 0, 0); // focus 节点背景透明，否则是白色
//   }
// }
.close {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 140px;
  z-index: 10000;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}
.groupTitle {
  width: 240px;
  height: 40px;
  position: absolute;
  left: 900px;
  top: 160px;
  z-index: 10000;
  font-size: 40px;
  font-family: 'ShiShangZhongHeiJianTi';
  color: #fff;
}
.btn {
  width: 400px;
  height: 40px;
  position: absolute;
  left: 800px;
  top: 900px;
  cursor: pointer;
}

.sp_item {
  width: 20%;
}
.show_box {
  position: absolute;
  z-index: 199;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  // background-color: #fff;

  .sp_body {
    width: 100%;
    height: 960px;
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    z-index: 10000;
    // background-color: #fff;

    .title {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      margin-bottom: 35px;

      .title_title {
        width: 240px;
        height: 40px;
        font-size: 40px;
        font-family: 'ShiShangZhongHeiJianTi';
        color: #fff;
        margin-left: 880px;
      }
      .title_X {
        height: 40px;
        font-size: 40px;
        font-family: 'ShiShangZhongHeiJianTi';
        color: #fff;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>