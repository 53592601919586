<template>
  <div class="cummode_box">
    <div class="cun_inner" v-for="(item, index) in videoList" :key="index">
      <div :id="'video' + index"></div>
    </div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
export default {
  data() {
    return {
      player: null,
      data: {},
      videoList: [],
    };
  },
  methods: {
    initData(data, pingfangziaccessToke) {
      // console.log("show----->", data);
      this.videoList = data;
      this.$nextTick(() => {
        data.forEach((item, index) => {
          this.player = new EZUIKit.EZUIKitPlayer({
            autoplay: true,
            id: "video" + index,
            accessToken: pingfangziaccessToke,
            url: item.cameraUrl,
            template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
            audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
            width: "100%",
            height: "200px",
          });
        });
      });
    },

    close() {
      if (this.player) {
        this.player.stop();
        this.player = null;
        console.log(11111);
      }
      this.$nextTick(() => {
        this.$emit("closeAllVideo");
        console.log(22222);
        
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cummode_box {
  width: 100%;
  // height: 606px;
  background: rgba($color: #000000, $alpha: 0.5);
  // position: absolute;
  color: #fff;
  // background-color: #fff;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .cun_inner {
    height: 200px;
    width: 20%;
    // background-color: #bc4343;

    // .close {
    //   width: 30px;
    //   height: 30px;
    //   position: absolute;
    //   right: 10px;
    //   top: 7px;
    //   z-index: 10000;
    //   font-size: 29px;
    //   color: #fff;
    //   cursor: pointer;
    //   text-align: center;
    //   line-height: 30px;
    // }
  }
}
</style>

// <style lang="scss">
// .el-table {
//   background-color: transparent !important;
// }
// .el-table th.el-table__cell,
// .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
//   background-color: transparent !important;
// }
// .el-table tr {
//   background-color: transparent !important;
// }
// .el-table .cell {
//   color: #fff;
// }
// .el-table th.el-table__cell > .cell {
//   color: #fff;
// }
// .el-table td.el-table__cell,
// .el-table th.el-table__cell.is-leaf {
//   border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
// }
// .el-table--enable-row-hover .el-table__body tr:hover > td {
//   background-color: transparent !important;
//   /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
// }
// .el-table--border::after,
// .el-table--group::after,
// .el-table::before {
//   background-color: transparent !important;
// }
// .el-table .el-table__body-wrapper::-webkit-scrollbar {
//   /*滚动条整体样式*/
//   width: 5px !important; /*高宽分别对应横竖滚动条的尺寸*/
//   height: 1px !important;
// }
// .el-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
//   /*滚动条里面小方块*/
//   border-radius: 10px !important;
//   box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
//   -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
//   background: #535353 !important;
// }
// .el-table .el-table__body-wrapper::-webkit-scrollbar-track {
//   /*滚动条里面轨道*/
//   border-radius: 10px !important;
//   background: #ededed !important;
// }
//
</style>
