<template>
  <div class="cummode" style="z-index: 9999">
    <div class="cun_inner">
      <div class="modeTop">
        <div class="flex_center_align">
          <div style="margin-left: 16px">{{data.label || data.cameraName}}</div>
        </div>
        <div class="modeTop_close" @click="close">
          <img class="mode_img" src="@/assets/img/close1.png" />
        </div>
      </div>
      <div id="video"></div>
    </div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
export default {
  data() {
    return {
      player: null,
      data:{}
    };
  },
  methods: {
    initData(data,val, pingfangziaccessToke) {
      this.data = data
      this.player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: "video",
        accessToken: pingfangziaccessToke,
        url: val == 'right'? data.cameraUrl: data.url,
        template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
        width: "100%",
        height: "700px",
      });
    },

    close() {
      if (this.player) {
        this.player.stop();
        this.player = null;
      }
      this.$nextTick(()=>{
        this.$emit('closeOneVideo')
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.cummode {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  color: #fff;
  .cun_inner {
    width: 1139px;
    height: 734px;
    position: absolute;
    left: 391px;
    top: 173px;
    z-index: 1000;
    padding: 27px 10px;
    border-radius: 10px;
    animation-name: small-to-big;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    @keyframes small-to-big {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
    .modeTop {
      width: 100%;
      height: 42px;
      background-image: url("../assets/img/titlebg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: JDLangZhengTi;
      font-size: 26px;
      font-weight: 400;
      margin-bottom: 7px;

      &_img {
        display: block;
        margin-left: 14px;
      }
      &_close {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;

        .mode_img {
          flex: 1;
        }
      }
    }

    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 7px;
      z-index: 10000;
      font-size: 29px;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
    }
  }
}
// .box::-webkit-scrollbar {
//   /*滚动条整体样式*/
//   width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
//   height: 1px;
// }
// .box::-webkit-scrollbar-thumb {
//   /*滚动条里面小方块*/
//   border-radius: 10px;
//   box-shadow: inset 0 0 5px #0d53b7;
//   -webkit-box-shadow: inset 0 0 5px #0d53b7;
//   background: #535353;
// }
// .box::-webkit-scrollbar-track {
//   /*滚动条里面轨道*/
//   border-radius: 10px;
//   background: transparent;
// }
//
</style>
// <style lang="scss">
// .el-table {
//   background-color: transparent !important;
// }
// .el-table th.el-table__cell,
// .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
//   background-color: transparent !important;
// }
// .el-table tr {
//   background-color: transparent !important;
// }
// .el-table .cell {
//   color: #fff;
// }
// .el-table th.el-table__cell > .cell {
//   color: #fff;
// }
// .el-table td.el-table__cell,
// .el-table th.el-table__cell.is-leaf {
//   border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
// }
// .el-table--enable-row-hover .el-table__body tr:hover > td {
//   background-color: transparent !important;
//   /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
// }
// .el-table--border::after,
// .el-table--group::after,
// .el-table::before {
//   background-color: transparent !important;
// }
// .el-table .el-table__body-wrapper::-webkit-scrollbar {
//   /*滚动条整体样式*/
//   width: 5px !important; /*高宽分别对应横竖滚动条的尺寸*/
//   height: 1px !important;
// }
// .el-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
//   /*滚动条里面小方块*/
//   border-radius: 10px !important;
//   box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
//   -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
//   background: #535353 !important;
// }
// .el-table .el-table__body-wrapper::-webkit-scrollbar-track {
//   /*滚动条里面轨道*/
//   border-radius: 10px !important;
//   background: #ededed !important;
// }
</style>
